import React, { useEffect, useState } from "react";
import "../../assets/stylesheets/CourseCard.css";
import EditIcon from "@mui/icons-material/Edit";
import PublishIcon from "@mui/icons-material/Publish";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import LockOpenIcon from "@mui/icons-material/LockOpen";

const CourseCard = ({
  id,
  name,
  instructor,
  description,
  published,
  color,
  price,
  has_paid,
  image_url,
  font_color,
  onEdit,
  onPublish,
  onDelete,
  onSelect,
  adminStatus,
}) => {
  console.log("Font color", font_color);
  const styles = {
    background: image_url ? `url(${image_url}) no-repeat center/cover` : color,
    color: font_color || "inherit",
  };

  return (
    <div className="course-card" style={styles} onClick={() => onSelect(id)}>
      <div className="course-content">
        <h3 className="course-title">{name}</h3>
        <p className="course-prescription">{description} </p>
        {/* <p className="instructor-name">🎓 {instructor}</p> */}
        <div className="course-price">
          <span>{price !== "0.0" ? `€${price}` : "Kostenlos"}</span>
          {price !== "0.0" &&
            (has_paid ? <LockOpenIcon /> : <ShoppingCartIcon />)}
        </div>
      </div>
      {adminStatus && (
        <div className="course-actions">
          <IconButton onClick={onEdit} aria-label="edit">
            <EditIcon />
          </IconButton>
          <IconButton
            onClick={onPublish}
            aria-label="publish"
            style={{ color: published ? "white" : undefined }}
          >
            <PublishIcon />
          </IconButton>
          <IconButton onClick={onDelete} aria-label="delete">
            <DeleteIcon />
          </IconButton>
        </div>
      )}
    </div>
  );
};

export default CourseCard;
