export const isArrayEmpty = (arr) => {
  if (arr !== undefined && arr !== null && arr.length > 0) {
    return true;
  }
  return false;
};

export const getAccessToken = () => {
  var object = JSON.parse(localStorage.getItem("access_token"));
  if (object == null) {
    return null;
  }
  var dateString = null;
  var now = null;
  if (object) {
    dateString = object.expiration;
    now = new Date().getTime().toString();
  }
  if (!object || now > dateString) {
    if (object.userToken !== undefined) {
      localStorage.removeItem(object);
    }
    // return window.location.reload();
    return null;
  }
  return object.userToken === "undefined" || object.userToken === undefined
    ? null
    : object.userToken;
};

export const isActive = (date, numWeeks) => {
  const createdAtDate = new Date(date);
  if (
    createdAtDate.setDate(createdAtDate.getDate() + numWeeks * 7) > new Date()
  ) {
    return true;
  }
  return false;
};

export const getEndDateOfProgram = (date, numWeeks) => {
  const createdAtDate = new Date(date);
  return createdAtDate.setDate(createdAtDate.getDate() + numWeeks * 7);
};

export const programInterval = (param) => {
  switch (param) {
    case "7":
      return "täglich";
    default:
      return param + "mal pro Woche";
  }
};

export const getTargetName = (param) => {
  switch (param) {
    case "1":
      return "Schmerzlinderung";
    case "2":
      return "Kräftigung";
    case "3":
      return "Beweglichkeit";
    case "4":
      return "Koordination";
    case "5":
      return "Prävention";
    case "6":
      return "Entspannung";
    case "7":
      return "Asudauer";
    default:
      return "Schmerzliderung";
  }
};

export const formatDate = (date) => {
  const day = date.getDate().toString().padStart(2, "0");
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const year = date.getFullYear().toString().slice(-2);
  const hours = date.getHours().toString().padStart(2, "0");
  const minutes = date.getMinutes().toString().padStart(2, "0");
  return `${day}.${month}.${year} ${hours}:${minutes}`;
};
