import React, { useState, useEffect } from "react";
import {
  Modal,
  Button,
  Form,
  FormGroup,
  FormControl,
  FormLabel,
} from "react-bootstrap";
import { CircularProgress } from "@mui/material";
import { SketchPicker } from "react-color";
import CloseIcon from "@mui/icons-material/Close";
import { createData, updateData } from "../../api/api";

export default function CategoryModal(props) {
  const [loading, setLoading] = useState(false);
  const [categoryData, setCategoryData] = useState({
    name: "",
    subcategories: [],
    bg_color: "#ffffff",
    font_color: "#000000",
  });
  const [newSubcategory, setNewSubcategory] = useState("");
  const [newSubcategoryBgColor, setNewSubcategoryBgColor] = useState("#ffffff");
  const [newSubcategoryFontColor, setNewSubcategoryFontColor] =
    useState("#000000");

  useEffect(() => {
    if (props.categoryData) {
      setCategoryData(props.categoryData);
    }
  }, [props.categoryData]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    const isNewEntry = !props.categoryData.id;

    try {
      if (!isNewEntry) {
        await updateData("categories", props.categoryData.id, categoryData);
        props.updateCategory(categoryData);
        props.handleSnackbar("Kategorie erfolgreich aktualisiert", "success");
        props.onHide();
        return;
      }
      const response = await createData("categories", categoryData, true);
      const newCategoryId = response.id;
      const updatedCategoryData = { ...categoryData, id: newCategoryId };
      props.handleSnackbar("Kategorie erfolgreich erstellt", "success");
      props.updateCategory(updatedCategoryData);
      props.onHide();
    } catch (error) {
      props.handleSnackbar(
        "Fehler beim Erstellen/Bearbeiten der Kategorie",
        "error"
      );
    } finally {
      setLoading(false);
    }
  };

  const handleAddSubcategory = () => {
    if (newSubcategory.trim() !== "") {
      const subcategory = {
        name: newSubcategory,
        bg_color: newSubcategoryBgColor,
        font_color: newSubcategoryFontColor,
      };
      setCategoryData({
        ...categoryData,
        subcategories: [...categoryData.subcategories, subcategory],
      });
      setNewSubcategory("");
      setNewSubcategoryBgColor("#ffffff");
      setNewSubcategoryFontColor("#000000");
    }
  };

  const handleRemoveSubcategory = (index) => {
    const updatedSubcategories = categoryData.subcategories.filter(
      (_, i) => i !== index
    );
    setCategoryData({ ...categoryData, subcategories: updatedSubcategories });
  };

  const handleBgColorChange = (color) => {
    setCategoryData({ ...categoryData, bg_color: color.hex });
  };

  const handleFontColorChange = (color) => {
    setCategoryData({ ...categoryData, font_color: color.hex });
  };

  return (
    <Modal {...props} size="lg" centered>
      <Form onSubmit={handleSubmit}>
        <Modal.Header closeButton>
          <Modal.Title>Kategorie erstellen/bearbeiten</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <FormGroup controlId="categoryName">
            <FormLabel className="form-label">Name</FormLabel>
            <FormControl
              type="text"
              placeholder="Kategorienamen angeben"
              value={categoryData.name}
              onChange={(e) =>
                setCategoryData({ ...categoryData, name: e.target.value })
              }
              required
            />
          </FormGroup>
          <div className="color-pickers-container">
            <FormGroup controlId="bgColor" className="color-picker">
              <FormLabel className="form-label">Hintergrundfarbe</FormLabel>
              <SketchPicker
                color={categoryData.bg_color}
                onChangeComplete={handleBgColorChange}
              />
            </FormGroup>
            <FormGroup controlId="fontColor" className="color-picker">
              <FormLabel className="form-label">Schriftfarbe</FormLabel>
              <SketchPicker
                color={categoryData.font_color}
                onChangeComplete={handleFontColorChange}
              />
            </FormGroup>
          </div>
          <FormGroup controlId="subcategories">
            <FormLabel className="form-label">Unterkategorien</FormLabel>
            <div style={{ display: "flex", marginBottom: "10px" }}>
              <FormControl
                type="text"
                placeholder="Unterkategorie hinzufügen"
                value={newSubcategory}
                onChange={(e) => setNewSubcategory(e.target.value)}
              />
              <Button
                onClick={handleAddSubcategory}
                style={{ marginLeft: "10px" }}
              >
                Hinzufügen
              </Button>
            </div>
            <div className="color-pickers-container">
              <FormGroup controlId="subBgColor" className="color-picker">
                <FormLabel className="form-label">Hintergrundfarbe</FormLabel>
                <SketchPicker
                  color={newSubcategoryBgColor}
                  onChangeComplete={(color) =>
                    setNewSubcategoryBgColor(color.hex)
                  }
                />
              </FormGroup>
              <FormGroup controlId="subFontColor" className="color-picker">
                <FormLabel className="form-label">Schriftfarbe</FormLabel>
                <SketchPicker
                  color={newSubcategoryFontColor}
                  onChangeComplete={(color) =>
                    setNewSubcategoryFontColor(color.hex)
                  }
                />
              </FormGroup>
            </div>
            <ul
              style={{ listStyleType: "none", padding: 0, marginTop: "20px" }}
            >
              <b>
                <i>Erstellte Unterkategorien:</i>
              </b>
              {categoryData.subcategories.map((subcategory, index) => (
                <li
                  key={index}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    padding: "10px",
                    border: "1px solid #ddd",
                    borderRadius: "4px",
                    marginBottom: "10px",
                    backgroundColor: subcategory.bg_color,
                    color: subcategory.font_color,
                  }}
                >
                  <span style={{ flex: 1 }}>{subcategory.name}</span>
                  <span
                    style={{
                      backgroundColor: subcategory.bg_color,
                      padding: "0 5px",
                    }}
                  >
                    BG
                  </span>
                  <span
                    style={{ color: subcategory.font_color, padding: "0 5px" }}
                  >
                    Font
                  </span>
                  <CloseIcon
                    style={{ cursor: "pointer", marginLeft: "10px" }}
                    onClick={() => handleRemoveSubcategory(index)}
                  />
                </li>
              ))}
            </ul>
          </FormGroup>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.onHide}>
            Abbrechen
          </Button>
          <Button type="submit" disabled={loading}>
            {loading ? <CircularProgress size={20} /> : "Speichern"}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}

CategoryModal.defaultProps = {
  categoryData: {
    name: "",
    subcategories: [],
    bg_color: "#ffffff",
    font_color: "#000000",
  },
  setCategoryData: () => {},
};
