import React, { useContext } from "react";
import { AuthContext } from "../../utils/AuthProvider";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { NavLink } from "react-router-dom";
import useMediaQuery from "@mui/material/useMediaQuery";
import MobileMenu from "./MobileMenu";
import Header from "../Header";
import { useTranslation } from "react-i18next";
import "../../assets/stylesheets/Menu.css";
// import DesktopMenu from "./DesktopMenu";

export default function Menu(props) {
  const { adminUser } = useContext(AuthContext);
  const isMobile = useMediaQuery("(max-width:900px)");
  const { t } = useTranslation();
  const logout = () => {
    localStorage.removeItem("access_token");
    window.location.reload();
  };
  // isMobile ? (
  //   <MobileMenu />
  // ) : (
  const isHiddenOverflow =
    props.content.type.name === "NewProgram" ||
    props.content.type.name === "Lk" ||
    props.content.type.name === "Patients" ||
    props.content.type.name === "ET" ||
    window.location.pathname.includes("/programs/create");

  var menu = isMobile ? (
    <Row>
      <Col className="program-overview" id="program-overview">
        {props.content}
      </Col>
      <MobileMenu />
    </Row>
  ) : (
    // <DesktopMenu content={props.content} isHiddenOverflow={isHiddenOverflow} />
    <Row>
      <Col md={1} className="app-menu vh-100 d-xxs-none d-md-block">
        <NavLink to="/dashboard" activeclassname="active">
          <Row className="menu-row align-items-center">
            <div className="menu-item no-title">
              <img src="/images/programs_icon.svg" alt="dashboard" />
              <span className="menu-title">{t("menu.courses")}</span>
            </div>
          </Row>
        </NavLink>
        {adminUser && (
          <NavLink to="/management" activeclassname="active">
            <Row className="menu-row align-items-center">
              <div className="menu-item no-title">
                <img src="/images/patienten.svg" alt="users" />
                <span className="menu-title">{t("menu.users")}</span>
              </div>
            </Row>
          </NavLink>
        )}
        <NavLink to="/profile" activeclassname="active">
          <Row className="menu-row align-items-center">
            <div className="menu-item no-title">
              <img src="/images/Benutzer.svg" alt="account" />
              <span className="menu-title">{t("menu.account")}</span>
            </div>
          </Row>
        </NavLink>
        <NavLink to="/categories" activeclassname="active">
          <Row className="menu-row align-items-center">
            <div className="menu-item no-title">
              <img src="/images/uebungen.svg" alt="categories" />
              <span className="menu-title">Kategorien</span>
            </div>
          </Row>
        </NavLink>
        <NavLink to="/preferences" activeclassname="active">
          <Row className="menu-row align-items-center">
            <div className="menu-item no-title">
              <img src="/images/dashboard_icon.svg" alt="settings" />
              <span className="menu-title">Zahlungen</span>
            </div>
          </Row>
        </NavLink>
        <Row className="menu-footer d-none d-xxxl-block">
          <Col className="menu-footer-title">© hoferdigital gmbh</Col>
        </Row>
      </Col>
      <Col
        className="program-overview"
        id="program-overview"
        style={{
          overflowY: isHiddenOverflow ? "hidden" : "scroll",
          position: "relative",
        }}
      >
        {props.content}
      </Col>
    </Row>
  );
  return (
    <Container fluid>
      <Header logout={logout} />
      {menu}
    </Container>
  );
}
