import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import PaymentIcon from "@mui/icons-material/Payment";
import { fetchData } from "../../api/api";
import "../../assets/stylesheets/Payment.css";
import { formatDate } from "../../utils/Utils";

export default function Settings() {
  const { t } = useTranslation();
  const [totalPaid, setTotalPaid] = useState(0);
  const [transactions, setTransactions] = useState([]);

  useEffect(() => {
    const fetchPayments = async (endpoint) => {
      try {
        const response = await fetchData("payments");
        setTransactions(response);
        if (Array.isArray(response)) {
          const total = response.length;
          setTotalPaid(total);
        }
      } catch (error) {
        console.error(error);
      }
    };
    fetchPayments();
  }, []);

  return (
    <div className="payment-container">
      <Card className="payment-card">
        <CardContent className="payment-card-content">
          <PaymentIcon className="payment-icon" />
          <Typography variant="h6" className="payment-title">
            Gekaufte Kurse:
          </Typography>
          <Typography variant="h4" className="payment-amount">
            {totalPaid}
          </Typography>
        </CardContent>
      </Card>

      <TableContainer component={Paper} className="transaction-table">
        <Table aria-label="transaction history">
          <TableHead>
            <TableRow>
              <TableCell>Datum</TableCell>
              <TableCell align="right">Betrag</TableCell>
              <TableCell>Beschreibung</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {transactions &&
              transactions.map((row) => (
                <TableRow key={row.id}>
                  <TableCell>
                    {formatDate(new Date(row.payment_date))}
                  </TableCell>
                  <TableCell align="right">€{row.amount}</TableCell>
                  <TableCell>{row.program.name}</TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
