import React, { useState } from "react";
import { Modal, Button, Form, FormGroup, FormControl } from "react-bootstrap";
import { CircularProgress } from "@mui/material";
import { createData, updateData } from "../../api/api";

export default function FeedbackModal(props) {
  const [feedback, setFeedback] = useState("");
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    try {
      // Replace with the actual function to send feedback to the backend.
      // The API endpoint and data format will depend on your backend implementation.
      const response = await createData("accounts/feedback", { feedback });
      props.handleSnackbar("Feedback erfolgreich gesendet", "success");
      props.onHide();
    } catch (error) {
      props.handleSnackbar("Fehler beim Senden des Feedbacks", "error");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal {...props} size="md" centered>
      <Form onSubmit={handleSubmit}>
        <Modal.Header closeButton>
          <Modal.Title>Feedback</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <FormGroup>
            <FormControl
              as="textarea"
              rows={3}
              value={feedback}
              onChange={(e) => setFeedback(e.target.value)}
              placeholder="Manches braucht Zeit zum wirken, aber Feedback ist willkommen..."
              required
            />
          </FormGroup>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.onHide}>
            Schließen
          </Button>
          <Button type="submit" disabled={loading}>
            {loading ? <CircularProgress size={20} /> : "Senden"}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}
