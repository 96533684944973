import React, { useState } from "react";
import { updatePassword } from "../../api/api";
import "../../assets/stylesheets/Profile.css";
import { useParams, useNavigate } from "react-router-dom";

function ResetPasswordForm() {
  const { token } = useParams();
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordRepeat, setPasswordRepeat] = useState("");

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (password !== passwordRepeat) {
      alert("Die Kennwörter stimmen nicht überein.");
      return; // Prevent the form from being submitted if passwords don't match
    }
    if (password.length < 8) {
      alert("Passwort muss mindestens eine Länge von 8 Zeichen haben.");
      return; // Prevent the form from being submitted if the password is too short
    }
    try {
      const response = await updatePassword("/account/reset_password_action", {
        email,
        password,
        token,
      });
      alert(
        "Passwort wurde erfolgreich zurückgesetzt. Du wirst nun zur Anmeldeseite weitergeleitet."
      );
      navigate("/login");
      // Here, you might want to redirect the user to the login page or do other follow-up actions
    } catch (error) {
      alert("Etwas ist schiefgelaufen, bitte probiere es erneut.");
      // Handle errors, possibly show an error message
    }
  };

  return (
    <div className="profile-container">
      <form className="profile-form" onSubmit={handleSubmit}>
        <h1>Passwort zurücksetzen</h1>
        <label htmlFor="email">Email</label>
        <input
          type="email"
          id="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <label htmlFor="password">Neues Passwort</label>
        <input
          type="password"
          id="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
        />
        <label htmlFor="passwordRepeat">Passwort wiederholen</label>
        <input
          type="password"
          id="passwordRepeat"
          value={passwordRepeat}
          onChange={(e) => setPasswordRepeat(e.target.value)}
          required
        />
        <button type="submit">Passwort zurücksetzen</button>
      </form>
    </div>
  );
}

export default ResetPasswordForm;
