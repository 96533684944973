import React from "react";
import { Modal, Button, Form } from "react-bootstrap";

const GroupSelectModal = ({
  selectedCourse,
  open,
  onClose,
  groups,
  publishStatus,
  onGroupToggle,
  selectedGroups,
  selectAllGroups,
  deselectAllGroups,
  isAllSelected,
  publishAllUsers,
  publishToGroups,
}) => {
  const statusText = publishStatus ? "Alle User" : "Gruppenrichtlinie";
  const statusColor = statusText === "Alle User" ? "green" : "blue";
  const buttonLabel = publishStatus
    ? "Veröffentlichung zurückziehen"
    : "Für alle Benutzer veröffentlichen";
  const buttonVariant = publishStatus ? "warning" : "secondary";

  const handleSelectAllToggle = () => {
    if (isAllSelected()) {
      deselectAllGroups();
    } else {
      selectAllGroups();
    }
  };

  return (
    <Modal show={open} onHide={onClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>
          Veröffentlichung{" "}
          <span style={{ fontSize: "0.75em", color: statusColor }}>
            (Status: {statusText})
          </span>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Check
            type="checkbox"
            label="--- Alle Gruppen ---"
            checked={isAllSelected()}
            onChange={handleSelectAllToggle}
            id="check-all-groups"
          />
          {groups.map((group) => (
            <Form.Check
              type="checkbox"
              label={group.name}
              checked={selectedGroups.includes(group.id)}
              onChange={() => onGroupToggle(group.id)}
              key={group.id}
              id={`group-${group.id}`}
            />
          ))}
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant={buttonVariant}
          onClick={(event) => publishAllUsers(selectedCourse, event)}
        >
          {buttonLabel}
        </Button>
        <Button
          variant="primary"
          onClick={(event) => publishToGroups(selectedCourse, event)}
        >
          Gruppenrichtline Speichern
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default GroupSelectModal;
