import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import CourseForm from "../../components/Form/CourseForm";
import CourseList from "../../components/Course/CourseList";
import CourseDetails from "../../components/Course/CourseDetails";
import "../../assets/stylesheets/Programs.css";
import CourseModal from "../../components/Modals/CourseModal";
import { Button, Snackbar } from "@mui/material";
import FilterButtons from "../../components/Buttons/FilterButtons";
import CustomSnackbar from "../../components/Snackbar/CustomSnackbar";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import IconButton from "@mui/material/IconButton";
import isAdmin from "../../utils/isAdmin";
import GroupSelectModal from "../../components/Modals/GroupSelectModal";
import FilterSort from "../../components/Buttons/FilterSort";
import { fetchData, deleteData, updateData } from "../../api/api";

const Dashboard = () => {
  const [courses, setCourses] = useState([]);
  const [editingId, setEditingId] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [selectedCourse, setSelectedCourse] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarType, setSnackbarType] = useState("success");
  const [adminStatus, setAdminStatus] = useState(false);
  const [groupModalOpen, setGroupModalOpen] = useState(false);
  const [isGroupModalOpen, setIsGroupModalOpen] = useState(false);
  const [selectedGroups, setSelectedGroups] = useState([]);
  const [groups, setGroups] = useState([]);
  const [currentCourseId, setCurrentCourseId] = useState(null);
  const [currentCourse, setCurrentCourse] = useState(null);
  const [filter, setFilter] = useState("all"); // Possible values: 'all', 'paid', 'free'
  const [sort, setSort] = useState("name");
  const handleOpenModal = () => setShowModal(true);
  // const handleCloseModal = () => setShowModal(false);
  const [courseData, setCourseData] = useState({
    name: "",
    category: "",
    unit_name: "Einheiten",
    description: "",
    color: "#ffffff",
    orderRequired: false,
    exercises: [],
    font_color: "#000000",
    image: "",
    valid_from: "",
    valid_till: "",
    attachments: [],
    links: [],
  });

  useEffect(() => {
    const fetchCourses = async () => {
      try {
        const courses = await fetchData("programs");
        setCourses(courses);
      } catch (error) {
        console.error("Failed to fetch courses:", error);
      }
    };

    fetchCourses();
  }, []);

  useEffect(() => {
    const checkAdminStatus = async () => {
      const status = await isAdmin();
      setAdminStatus(status.isAdmin);
      if (status.isAdmin) {
        fetchGroups();
      } else {
        console.log("Keine Berechtigung");
      }
    };

    checkAdminStatus();
  }, []);

  const fetchGroups = async () => {
    try {
      const response = await fetchData("accounts/get_groups");
      setGroups(response);
    } catch (error) {
      console.error("Error fetching groups:", error);
    }
  };

  useEffect(() => {
    const fetchSelectedGroups = async () => {
      if (!isGroupModalOpen) return;

      try {
        const response = await fetchData(
          "programs/get_published_groups/" + currentCourseId
        );
        setSelectedGroups(response.groups.map((group) => group.group_id));
      } catch (error) {
        console.error("Error fetching groups:", error);
        handleSnackbar("Failed to load groups", "error");
      }
    };

    // Call the fetchGroups function
    fetchSelectedGroups();
  }, [isGroupModalOpen, currentCourseId]); // Dependencies on modal state and selected course ID

  const handleCloseModal = () => {
    setCourseData({
      name: "",
      category: "",
      description: "",
      color: "#ffffff",
      image: null,
      orderRequired: false,
      exercises: [],
    });

    setShowModal(false);
  };

  const handleAddCourse = (newCourse) => {
    setCourses([...courses, newCourse]);
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleCourseSelect = (courseId) => {
    const element = document.getElementById("program-overview");
    if (element) {
      element.scrollTop = 0;
    }
    const course = courses.find((c) => c.id === courseId);
    setSelectedCourse(course);
  };

  const handleEditCourse = (courseId, event) => {
    event.stopPropagation();
    const courseToEdit = courses.find((course) => course.id === courseId);
    if (!courseToEdit) {
      handleSnackbar("Kurs nicht gefunden!", "error");
      return;
    }
    setCourseData(courseToEdit);
    setShowModal(true);
  };

  const handlePublishCourse = async (courseId, event) => {
    event.stopPropagation();
    setGroupModalOpen(false);
    const courseToUpdate = courses.find((course) => course.id === courseId);
    if (!courseToUpdate) {
      handleSnackbar("Course not found or an error has occurred!", "error");
      return;
    }

    const updatedCourseData = {
      ...courseToUpdate,
      published: true,
      groups: selectedGroups, // Ensure your backend can handle this payload
    };

    try {
      await updateData("programs", courseId, updatedCourseData);
      handleSnackbar("Course published successfully", "success");
    } catch (error) {
      handleSnackbar("Failed to publish course!", "error");
    }

    handleCloseGroupModal();
  };

  const handlePublishCourseForAllUsers = async (courseId, event) => {
    event.stopPropagation();
    const courseToUpdate = courses.find((course) => course.id === courseId);
    const courseIndex = courses.findIndex((course) => course.id === courseId);

    if (!courseToUpdate) {
      handleSnackbar(
        "Kurs nicht gefunden oder ein Fehler ist aufgetreten!",
        "error"
      );
      return;
    }

    // Toggle the published_for_all status
    const isCurrentlyPublishedForAll = courseToUpdate.published_for_all;
    const updatedCourseData = {
      ...courseToUpdate,
      published_for_all: !courseToUpdate.published_for_all,
      published: true,
    };

    try {
      const response = await updateData(
        "programs",
        courseId,
        updatedCourseData
      );
      const updatedCourses = [...courses];
      updatedCourses[courseIndex] = updatedCourseData;

      setCourses(updatedCourses);
      setCurrentCourse(updatedCourseData);
      if (!isCurrentlyPublishedForAll) {
        handleSnackbar(
          "Kurs wurde für alle erfolgreich veröffentlicht",
          "success"
        );
      } else {
        handleSnackbar(
          "Veröffentlichung des Kurses für alle wurde zurückgezogen",
          "success"
        );
      }
    } catch (error) {
      handleSnackbar(
        "Kurs konnte nicht veröffentlicht/entfernt werden!",
        "error"
      );
    }
  };

  const handlePublishCourseForGroups = async (courseId, event) => {
    event.stopPropagation();
    const courseToUpdate = courses.find((course) => course.id === courseId);
    const courseIndex = courses.findIndex((course) => course.id === courseId);

    if (!courseToUpdate) {
      handleSnackbar(
        "Kurs nicht gefunden oder ein Fehler ist aufgetreten!",
        "error"
      );
      return;
    }

    const isCurrentlyPublishedForAll = courseToUpdate.published_for_all;
    const updatedCourseData = {
      ...courseToUpdate,
      published_for_all: !courseToUpdate.published_for_all,
    };

    try {
      const response = await updateData(
        "programs/update_published_groups",
        courseId,
        { selectedGroups: selectedGroups }
      );
      handleSnackbar(
        "Kurs wurde für die Gruppen erfolgreich veröffentlicht/angepasst",
        "success"
      );
    } catch (error) {
      handleSnackbar(
        "Kurs konnte nicht veröffentlicht/entfernt werden!",
        "error"
      );
    }
  };

  const handleUpdateCourse = (updatedCourse) => {
    setCourses((prevCourses) => {
      const index = prevCourses.findIndex(
        (course) => course.id === updatedCourse.id
      );

      if (index !== -1) {
        const updatedCourses = [...prevCourses];
        updatedCourses[index] = updatedCourse;
        return updatedCourses;
      }

      return [...prevCourses, updatedCourse];
    });
  };

  const handleGroupToggle = (groupId) => {
    setSelectedGroups((prev) =>
      prev.includes(groupId)
        ? prev.filter((id) => id !== groupId)
        : [...prev, groupId]
    );
  };

  const handleCloseGroupModal = () => {
    setIsGroupModalOpen(false);
    setCurrentCourse(null);
  };

  const handleOpenGroupModal = (courseId, event) => {
    event.stopPropagation();
    const course = courses.find((course) => course.id === courseId);

    if (!course) {
      handleSnackbar("Kurs nicht gefunden!", "error");
      return;
    } else {
      setCurrentCourse(course);
    }
    setCurrentCourseId(courseId);
    setIsGroupModalOpen(true);
  };

  const selectAllGroups = () => {
    setSelectedGroups(groups.map((group) => group.id));
  };

  const deselectAllGroups = () => {
    setSelectedGroups([]);
  };

  const isAllSelected = () => {
    return groups.length === selectedGroups.length;
  };

  const handleDeleteCourse = async (courseId, event) => {
    event.stopPropagation();
    const alert = window.confirm(
      "Sind Sie sicher, dass Sie den Kurs löschen möchten?"
    );
    if (!alert) return;
    try {
      const response = await deleteData("programs", courseId);
      setCourses(courses.filter((course) => course.id !== courseId));
      handleSnackbar("Kurs wurde erfolgreich gelöscht", "success");
    } catch (error) {
      handleSnackbar("Kurs konnte nicht gelöscht werden!", "error");
    }
  };

  const toggleFilter = () => {
    setFilter((prevFilter) => {
      // Add your filter toggle logic here, e.g., cycle through filter states
      if (prevFilter === "all") return "paid";
      else if (prevFilter === "paid") return "free";
      return "all";
    });
  };

  const toggleSort = () => {
    setSort((prevSort) => (prevSort === "name" ? "date" : "name"));
  };

  const filteredCourses = courses.filter((course) => {
    if (filter === "paid") {
      return course.price > 0;
    } else if (filter === "free") {
      return course.free || Number(course.price) === 0;
    }
    return true;
  });

  const sortedFilteredCourses = filteredCourses.sort((a, b) => {
    if (sort === "name") {
      return a.name.localeCompare(b.name);
    } else if (sort === "date") {
      return new Date(b.updated_at) - new Date(a.updated_at);
    }
    return 0;
  });

  const handleSnackbar = (message, type) => {
    setSnackbarMessage(message);
    setSnackbarType(type);
    setSnackbarOpen(true);
  };

  return (
    <div className="dashboard">
      <Container>
        {selectedCourse ? (
          <>
            <Row className="action-header-small justify-content-start">
              <Col xs={12} md={2} lg={1} className="back-to-courses">
                <IconButton
                  onClick={() => setSelectedCourse(null)}
                  aria-label="back to courses"
                >
                  <ArrowBackIcon />
                  Kursübersicht
                </IconButton>
              </Col>
            </Row>
            <div className="course-details-dashboard">
              <CourseDetails
                course={selectedCourse}
                handleSnackbar={handleSnackbar}
              />
            </div>
          </>
        ) : (
          <>
            <Row className="action-header justify-content-end">
              <Col md="auto">
                <FilterSort setFilter={setFilter} setSort={setSort} />
              </Col>
              {adminStatus && (
                <Col
                  md={1}
                  lg={2}
                  className="dashboard-header-btn-new-program"
                  onClick={handleOpenModal}
                >
                  Neues Kurs
                  <img
                    className="dashboard-header-btn-icon"
                    src="/images/plus.svg"
                    alt="add program"
                  />
                </Col>
              )}
            </Row>
            {adminStatus && (
              <CourseModal
                show={showModal}
                onHide={handleCloseModal}
                courseData={courseData}
                setCourseData={setCourseData}
                handleSnackbar={handleSnackbar}
                courses={courses}
                setCourses={setCourses}
                updateCourse={handleUpdateCourse}
              />
            )}
          </>
        )}
        {sortedFilteredCourses.length > 0 ? (
          <CourseList
            courses={sortedFilteredCourses}
            onEdit={(id, event) => handleEditCourse(id, event)}
            onDelete={(id, event) => handleDeleteCourse(id, event)}
            // onPublish={(id, event) => handlePublishCourse(id, event)}
            onPublish={(id, event) => handleOpenGroupModal(id, event)}
            onSelect={handleCourseSelect}
            adminStatus={adminStatus}
          />
        ) : (
          <Row className="vh-100 justify-content-center align-items-center">
            <Col className="text-center">
              <div className="no-programs">
                Aktuell wurden noch keine Kurse erstellt...
              </div>
            </Col>
          </Row>
        )}
        <CustomSnackbar
          open={snackbarOpen}
          autoHideDuration={4000}
          handleClose={handleSnackbarClose}
          message={snackbarMessage}
          type={snackbarType}
        />
        <GroupSelectModal
          selectedCourse={currentCourseId}
          open={isGroupModalOpen}
          onClose={handleCloseGroupModal}
          groups={groups}
          publishStatus={currentCourse?.published_for_all}
          onGroupToggle={handleGroupToggle}
          selectedGroups={selectedGroups}
          selectAllGroups={selectAllGroups}
          deselectAllGroups={deselectAllGroups}
          isAllSelected={isAllSelected}
          publishAllUsers={handlePublishCourseForAllUsers}
          publishToGroups={handlePublishCourseForGroups}
        />
      </Container>
    </div>
  );
};

export default Dashboard;
