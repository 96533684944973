import React, { useState } from "react";
import { IconButton, Menu, MenuItem } from "@mui/material";
import FilterListIcon from "@mui/icons-material/FilterList";
import SortIcon from "@mui/icons-material/Sort";

const FilterSort = ({ setFilter, setSort }) => {
  // States for handling filter menu
  const [filterAnchorEl, setFilterAnchorEl] = useState(null);
  const isFilterMenuOpen = Boolean(filterAnchorEl);

  const handleFilterClick = (event) => {
    setFilterAnchorEl(event.currentTarget);
  };

  const handleFilterClose = () => {
    setFilterAnchorEl(null);
  };

  // States for handling sort menu
  const [sortAnchorEl, setSortAnchorEl] = useState(null);
  const isSortMenuOpen = Boolean(sortAnchorEl);

  const handleSortClick = (event) => {
    setSortAnchorEl(event.currentTarget);
  };

  const handleSortClose = () => {
    setSortAnchorEl(null);
  };

  // Event handlers for selecting filters
  const handleFilterSelection = (filter) => {
    setFilter(filter);
    handleFilterClose();
  };

  // Event handlers for selecting sorting options
  const handleSortSelection = (sort) => {
    setSort(sort);
    handleSortClose();
  };

  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <IconButton onClick={handleFilterClick}>
        <FilterListIcon />
      </IconButton>
      <Menu
        anchorEl={filterAnchorEl}
        open={isFilterMenuOpen}
        onClose={handleFilterClose}
      >
        <MenuItem onClick={() => handleFilterSelection("all")}>Alle</MenuItem>
        <MenuItem onClick={() => handleFilterSelection("paid")}>
          Bezahlt
        </MenuItem>
        <MenuItem onClick={() => handleFilterSelection("free")}>
          Kostenlos
        </MenuItem>
      </Menu>

      <IconButton onClick={handleSortClick}>
        <SortIcon />
      </IconButton>
      <Menu
        anchorEl={sortAnchorEl}
        open={isSortMenuOpen}
        onClose={handleSortClose}
      >
        <MenuItem onClick={() => handleSortSelection("name")}>Name</MenuItem>
        <MenuItem onClick={() => handleSortSelection("date")}>Datum</MenuItem>
      </Menu>
    </div>
  );
};

export default FilterSort;
