import React, { useState } from "react";
import axios from "axios";
import { createData } from "../../api/api";
import "../../assets/stylesheets/GroupForm.css";

function GroupForm({ users = [], fetchGroups }) {
  const [groupName, setGroupName] = useState("");
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [searchFilter, setSearchFilter] = useState("");

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!groupName.trim()) {
      alert("Please provide a name for the group.");
      return;
    }
    try {
      await createData(
        "accounts/create_group",
        { name: groupName, user_ids: selectedUsers },
        false
      );
      // await axios.post("/groups", { name: groupName, user_ids: selectedUsers });
      fetchGroups();
      setSelectedUsers([]); // Reset selection after group creation
      setGroupName(""); // Reset the group name field
    } catch (error) {
      console.error("Failed to create group:", error);
    }
  };

  const handleUserSelection = (userId) => {
    setSelectedUsers((prev) =>
      prev.includes(userId)
        ? prev.filter((id) => id !== userId)
        : [...prev, userId]
    );
  };

  const filteredUsers = users.filter((user) =>
    user.email.toLowerCase().includes(searchFilter.toLowerCase())
  );

  return (
    <form onSubmit={handleSubmit} className="group-form">
      <h3>Neue Gruppe erstellen</h3>
      <input
        type="text"
        placeholder="Gruppenname"
        value={groupName}
        onChange={(e) => setGroupName(e.target.value)}
        className="group-name-input"
      />
      <input
        type="text"
        placeholder="Benutzer suchen..."
        value={searchFilter}
        onChange={(e) => setSearchFilter(e.target.value)}
        className="search-input"
      />
      <div className="users-list-container">
        {filteredUsers.length > 0 ? (
          filteredUsers.map((user) => (
            <div
              key={user.id}
              onClick={() => handleUserSelection(user.id)}
              className={`user-selection-item ${
                selectedUsers.includes(user.id) ? "user-selected" : ""
              }`}
            >
              {user.email} (+ / -)
            </div>
          ))
        ) : (
          <p>Keine Benutzer verfügbar bzw. gefunden.</p>
        )}
      </div>
      <button type="submit">Speichern</button>
    </form>
  );
}

export default GroupForm;
