import React, { useState, useEffect } from "react";
import axios from "axios";
import UserList from "../../components/Lists/UserList";
import GroupList from "../../components/Lists/GroupList";
import GroupForm from "../../components/Form/GroupForm";
import "../../assets/stylesheets/UserManagement.css";
import { fetchData, updateData, deleteData } from "../../api/api";
import EditGroupModal from "../../components/Modals/EditGroupModal";
// import AssignProgramForm from "./AssignProgramForm";

function UserManagement() {
  const [users, setUsers] = useState([]);
  const [groups, setGroups] = useState([]);
  const [editDialogShow, setEditDialogShow] = useState(false);
  const [currentGroup, setCurrentGroup] = useState(null);

  useEffect(() => {
    fetchUsers();
    fetchGroups();
  }, []);

  const fetchUsers = async () => {
    try {
      const response = await fetchData("/accounts/get_users");
      setUsers(response);
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  const fetchGroups = async () => {
    try {
      const response = await fetchData("accounts/get_groups");
      setGroups(response);
    } catch (error) {
      console.error("Error fetching groups:", error);
    }
  };

  const deleteGroup = async (groupId) => {
    try {
      const response = await deleteData(`/accounts/destroy_group/`, groupId);
      if (response) {
        fetchGroups();
      }
    } catch (error) {
      console.error("Error deleting group:", error);
    }
  };

  const handleEditGroup = (selectedGroup) => {
    setCurrentGroup(selectedGroup);
    setEditDialogShow(true);
  };

  const saveGroupChanges = async (groupId, newName, selectedUsers) => {
    try {
      const response = await updateData(
        `/accounts/update_group`,
        groupId,
        { name: newName, user_ids: selectedUsers },
        false
      );
      fetchGroups(); // Re-fetch groups after update
    } catch (error) {
      console.error("Error updating group:", error);
    }
  };

  const toggleAdmin = async (userId) => {
    const userIndex = users.findIndex((user) => user.id === userId);
    if (userIndex > -1) {
      const updatedUsers = users.map((user) =>
        user.id === userId
          ? { ...user, role_id: user.role_id === "admin" ? "user" : "admin" }
          : user
      );
      try {
        await updateData(`/accounts/toggle_admin`, userId, {});
        setUsers(updatedUsers);
      } catch (error) {
        console.error("Error toggling admin status:", error);
      }
    }
  };

  return (
    <div className="user-management">
      <h1>Benutzermanagement</h1>
      <GroupForm users={users} fetchGroups={fetchGroups} />
      <GroupList
        groups={groups}
        fetchGroups={fetchGroups}
        deleteGroup={deleteGroup}
        editGroup={handleEditGroup}
      />
      <UserList users={users} toggleAdmin={toggleAdmin} />
      {currentGroup && (
        <EditGroupModal
          show={editDialogShow}
          handleClose={() => setEditDialogShow(false)}
          group={currentGroup}
          onSave={saveGroupChanges}
          allUsers={users}
        />
      )}
    </div>
  );
}

export default UserManagement;
