import React, { useState } from "react";
import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { PaymentElement } from "@stripe/react-stripe-js";
import "../../assets/stylesheets/CheckoutForm.css";
import { createData } from "../../api/api";

const CheckoutForm = ({ onPaymentSuccess, handleSnackbar }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState(null);
  const [paymentSuccess, setPaymentSuccess] = useState(false);

  const CARD_ELEMENT_OPTIONS = {
    style: {
      base: {
        color: "#32325d",
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSmoothing: "antialiased",
        fontSize: "16px",
        "::placeholder": {
          color: "#aab7c4",
        },
      },
      invalid: {
        color: "#fa755a",
        iconColor: "#fa755a",
      },
    },
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setError(null);
    setIsSubmitting(true);

    if (!stripe || !elements) {
      setError("Stripe has not loaded yet.");
      setIsSubmitting(false);
      return;
    }

    // if (!response.ok) {
    //   setError("Network response was not ok.");
    //   setIsSubmitting(false);
    //   return;
    // }

    const { error, paymentIntent } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: window.location.href,
      },
      redirect: "if_required",
    });

    if (error) {
      setError(error.message);
      setIsSubmitting(false);
      return;
    } else if (paymentIntent.status === "succeeded") {
      setPaymentSuccess(true);
      onPaymentSuccess();
      handleSnackbar("Zahlung erfolgreich!", "success");
    } else {
      setError("Payment failed.");
      handleSnackbar("Zahlung konnte nicht durchgeführt werden", "error");
    }

    setIsSubmitting(false);
  };

  return (
    <form onSubmit={handleSubmit} className="checkout-form">
      <PaymentElement />
      <button
        type="submit"
        disabled={isSubmitting || !stripe}
        className="submit-button"
      >
        Bezahlen
      </button>
      {error && <div className="error">{error}</div>}
      {paymentSuccess && <div className="success">Payment succeeded!</div>}
    </form>
  );
};

export default CheckoutForm;
