import React, { useContext } from "react";
import useToken from "./useToken";
import { AuthContext } from "./AuthProvider";

function ProtectedRoute({ children, isAdminRoute = false }) {
  const { token } = useToken();
  const { adminUser } = useContext(AuthContext);
  if (!token) {
    return null;
  }

  if (isAdminRoute && !adminUser) {
    return null;
  } else {
    return children;
  }
}

export default ProtectedRoute;
