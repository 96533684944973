import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import CategoryModal from "../../components/Modals/CategoryModal";
import { Button, Snackbar } from "@mui/material";
import FilterSort from "../../components/Buttons/FilterSort";
import CustomSnackbar from "../../components/Snackbar/CustomSnackbar";
import isAdmin from "../../utils/isAdmin";
import { fetchData, deleteData, updateData } from "../../api/api";
import "../../assets/stylesheets/Categories.css";

const Categories = () => {
  const [categories, setCategories] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarType, setSnackbarType] = useState("success");
  const [adminStatus, setAdminStatus] = useState(false);
  const [categoryData, setCategoryData] = useState({
    name: "",
    subcategories: [],
    bg_color: "#ffffff",
    font_color: "#000000",
  });

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const categories = await fetchData("categories");
        setCategories(categories);
        console.log(categories);
      } catch (error) {
        console.error("Failed to fetch categories:", error);
      }
    };

    fetchCategories();
  }, []);

  useEffect(() => {
    const checkAdminStatus = async () => {
      const status = await isAdmin();
      setAdminStatus(status.isAdmin);
    };

    checkAdminStatus();
  }, []);

  const handleCloseModal = () => {
    setCategoryData({
      name: "",
      subcategories: [],
      bg_color: "#ffffff",
      font_color: "#000000",
    });
    setShowModal(false);
  };

  const handleAddCategory = (newCategory) => {
    setCategories([...categories, newCategory]);
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleEditCategory = (categoryId, event) => {
    event.stopPropagation();
    const categoryToEdit = categories.find(
      (category) => category.id === categoryId
    );
    if (!categoryToEdit) {
      handleSnackbar("Kategorie nicht gefunden!", "error");
      return;
    }
    setCategoryData(categoryToEdit);
    setShowModal(true);
  };

  const handleUpdateCategory = (updatedCategory) => {
    setCategories((prevCategories) => {
      const index = prevCategories.findIndex(
        (category) => category.id === updatedCategory.id
      );

      if (index !== -1) {
        const updatedCategories = [...prevCategories];
        updatedCategories[index] = updatedCategory;
        return updatedCategories;
      }

      return [...prevCategories, updatedCategory];
    });
  };

  const handleDeleteCategory = async (categoryId, event) => {
    event.stopPropagation();
    const alert = window.confirm(
      "Sind Sie sicher, dass Sie die Kategorie löschen möchten?"
    );
    if (!alert) return;
    try {
      await deleteData("categories", categoryId);
      setCategories(
        categories.filter((category) => category.id !== categoryId)
      );
      handleSnackbar("Kategorie wurde erfolgreich gelöscht", "success");
    } catch (error) {
      handleSnackbar("Kategorie konnte nicht gelöscht werden!", "error");
    }
  };

  const handleSnackbar = (message, type) => {
    setSnackbarMessage(message);
    setSnackbarType(type);
    setSnackbarOpen(true);
  };

  return (
    <div className="dashboard">
      <Container>
        <Row className="action-header justify-content-end">
          {/* <Col md="auto">
            <FilterSort setFilter={setCategories} setSort={() => {}} />
          </Col> */}
          {adminStatus && (
            <Col
              md={1}
              lg={2}
              className="dashboard-header-btn-new-program"
              onClick={() => setShowModal(true)}
            >
              Neue Kategorie
              <img
                className="dashboard-header-btn-icon"
                src="/images/plus.svg"
                alt="add category"
              />
            </Col>
          )}
        </Row>
        {adminStatus && (
          <CategoryModal
            show={showModal}
            onHide={handleCloseModal}
            categoryData={categoryData}
            setCategoryData={setCategoryData}
            handleSnackbar={handleSnackbar}
            categories={categories}
            setCategories={setCategories}
            updateCategory={handleUpdateCategory}
          />
        )}
        {categories.length > 0 ? (
          <Row className="category-list">
            {categories.map((category) => (
              <Col key={category.id} md={4} className="category-item">
                <div
                  className="category-card"
                  style={{
                    backgroundColor: category.bg_color,
                    color: category.font_color,
                  }}
                  onClick={() => setSelectedCategory(category.id)}
                >
                  <h5>{category.name}</h5>
                  <Button
                    variant="outlined"
                    className="btn-outlined"
                    onClick={(event) => handleEditCategory(category.id, event)}
                  >
                    Bearbeiten
                  </Button>
                  {/* <Button
                    variant="outlined"
                    className="btn-secondary"
                    onClick={(event) =>
                      handleDeleteCategory(category.id, event)
                    }
                  >
                    Löschen
                  </Button> */}
                </div>
              </Col>
            ))}
          </Row>
        ) : (
          <Row className="vh-100 justify-content-center align-items-center">
            <Col className="text-center">
              <div className="no-categories">
                Aktuell wurden noch keine Kategorien erstellt...
              </div>
            </Col>
          </Row>
        )}
        <CustomSnackbar
          open={snackbarOpen}
          autoHideDuration={4000}
          handleClose={handleSnackbarClose}
          message={snackbarMessage}
          type={snackbarType}
        />
      </Container>
    </div>
  );
};

export default Categories;
