import React, { useEffect, useState } from "react";
import { fetchData, updateProfile } from "../../api/api";
import "../../assets/stylesheets/Profile.css";
import FeedbackModal from "../../components/Modals/FeedbackModal";
import CustomSnackbar from "../../components/Snackbar/CustomSnackbar";

function checkPasswordPolicy(pw) {
  if (!pw || pw.length <= 7) {
    return false;
  } else {
    return true;
  }
}

export default function Profile() {
  const [password, setPassword] = useState();
  const [email, setEmail] = useState();
  const [name, setName] = useState();
  const [lastname, setLastname] = useState();
  const [createdAt, setCreatedAt] = useState();
  const [feedbackModalOpen, setFeedbackModalOpen] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarType, setSnackbarType] = useState("success");
  const handleCloseModal = () => setFeedbackModalOpen(false);

  useEffect(() => {
    const element = document.getElementById("program-overview");
    if (element) {
      element.scrollTop = 0;
    }
    const loadAccountData = async () => {
      const account = await fetchData("accounts/profile");
      if (account) {
        setName(account.first_name);
        setLastname(account.last_name);
        setEmail(account.email);
        setCreatedAt(account.created_at);
      }
    };

    loadAccountData();
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    const data = {
      first_name: name,
      last_name: lastname,
      email,
    };
    if (password && password.trim() !== "" && !checkPasswordPolicy(password)) {
      setSnackbarMessage("Passwort zu kurz", "error");
      setSnackbarType("error");
      setSnackbarOpen(true);
      return;
    }
    if (password && password.trim() !== "") {
      data.password = password;
    }
    updateData(e, data);
  };

  const updateData = async (e, data) => {
    const account = await updateProfile("accounts/update_profile", data);
    if (account) {
      setSnackbarMessage("Profil erfolgreich aktualisiert", "success");
      setSnackbarType("success");
      setSnackbarOpen(true);
    } else {
      setSnackbarMessage("Fehler beim Aktualisieren des Profils", "error");
      setSnackbarType("error");
      setSnackbarOpen(true);
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleSnackbar = (message, type) => {
    setSnackbarMessage(message);
    setSnackbarType(type);
    setSnackbarOpen(true);
  };

  const canShowFeedback = () => {
    const now = new Date();
    if (!createdAt) return true;
    const oneDayAfterRegistration = new Date(createdAt.getTime() + 86400000);
    return now >= oneDayAfterRegistration;
  };

  return (
    <div className="profile-container">
      <form className="profile-form" onSubmit={handleSubmit}>
        <h1>Mein Profil</h1>
        <label htmlFor="firstname">Vorname:</label>
        <input
          type="text"
          id="firstname"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />

        <label htmlFor="lastname">Nachname:</label>
        <input
          type="text"
          id="lastname"
          value={lastname}
          onChange={(e) => setLastname(e.target.value)}
        />

        <label htmlFor="email">Email:</label>
        <input
          type="email"
          id="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />

        <label htmlFor="password">Passwort neu setzen:</label>
        <input
          type="password"
          id="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />

        <button type="submit" className="profile-submit-button">Speichern</button>
        {canShowFeedback() && (
          <div
            className="feedback-link"
            onClick={() => setFeedbackModalOpen(true)}
          >
            Feedback hinterlassen
          </div>
        )}
        <FeedbackModal
          show={feedbackModalOpen}
          onHide={handleCloseModal}
          handleSnackbar={handleSnackbar}
        />
        <CustomSnackbar
          open={snackbarOpen}
          autoHideDuration={2000}
          handleClose={handleSnackbarClose}
          message={snackbarMessage}
          type={snackbarType}
        />
      </form>
    </div>
  );
}
