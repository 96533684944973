import React, { Suspense, useState, useEffect } from "react";
import "./App.css";
import Login from "./pages/Login/Login";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import useToken from "./utils/useToken";
import { withTranslation } from "react-i18next";
import { BrandingContext } from "./utils/Contexts/Context";
import routes from "./routes";
import ProtectedRoute from "./utils/ProtectedRoute";
import { AuthProvider } from "./utils/AuthProvider";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import CheckoutForm from "./components/Form/CheckoutForm";
import logo from "./assets/images/emblem.png";
import "./i18n";
import "./assets/stylesheets/Programs.css";

// const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

function App() {
  const [applicationName, setApplicationName] = useState("medapp");
  const [applicationImagePath, setApplicationImagePath] = useState(logo);
  const { token, setToken } = useToken();

  // outsource and manage in index.html
  useEffect(() => {
    const hostname = window.location.hostname;
    import("./assets/stylesheets/variables.css").then(() => {});
  }, []);

  const publicPaths = ["/reset-password/"];
  const currentPath = window.location.pathname;
  const isPublicRoute = publicPaths.some((path) =>
    currentPath.startsWith(path)
  );

  if (!token && !isPublicRoute) {
    return (
      <Login
        setToken={setToken}
        application={applicationName}
        imagePath={applicationImagePath}
      />
    );
  }
  return (
    <BrandingContext.Provider value={{ applicationName, applicationImagePath }}>
      <Suspense fallback="loading">
        <AuthProvider>
          <div className="main_background">
            <BrowserRouter>
              <Routes>
                {routes.map((route, index) => (
                  <Route
                    key={index}
                    path={route.path}
                    element={
                      route.isProtected ? (
                        <ProtectedRoute isAdminRoute={route.isAdminRoute}>
                          {route.element}
                        </ProtectedRoute>
                      ) : (
                        route.element
                      )
                    }
                  />
                ))}
              </Routes>
            </BrowserRouter>
          </div>
        </AuthProvider>
      </Suspense>
    </BrandingContext.Provider>
  );
}

export default withTranslation()(App);
