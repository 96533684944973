import { useState, useEffect } from "react";

export default function useToken() {
  const getToken = () => {
    const object = JSON.parse(localStorage.getItem("access_token"));
    if (!object) {
      return null;
    }

    const now = new Date().getTime();
    if (now > object.expiration) {
      localStorage.removeItem("access_token");
      return null;
    }

    return object.userToken;
  };

  const [token, setToken] = useState(getToken());

  useEffect(() => {
    setToken(getToken());
  }, []);

  const saveToken = (userToken) => {
    const now = Date.now();
    const expirationTime = 259200000; // aktuell 3 Tage, sonst 3h -> 12000 * 1000;
    localStorage.setItem(
      "access_token",
      JSON.stringify({ userToken, expiration: now + expirationTime })
    );
    setToken(userToken);
  };

  return {
    setToken: saveToken,
    token,
    getToken,
  };
}
