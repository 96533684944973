import React, { useState, useEffect } from "react";
import {
  Modal,
  Button,
  Form,
  FormGroup,
  FormControl,
  FormLabel,
  FormCheck,
} from "react-bootstrap";
import { CircularProgress } from "@mui/material";
import { Switch } from "@mui/material";
import { SketchPicker } from "react-color";
import CourseExerciseTabs from "../../utils/CourseExerciseTabs";
import { createData, updateData, deleteImage, fetchData } from "../../api/api";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import CloseIcon from "@mui/icons-material/Close";

export default function CourseModal(props) {
  const [modalContent, setModalContent] = useState("course");
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    const isNewEntry = !props.courseData.id;

    try {
      if (!isNewEntry) {
        await updateData("programs", props.courseData.id, props.courseData);
        props.updateCourse(props.courseData);
        props.handleSnackbar("Kurs erfolgreich aktualisiert", "success");
        props.onHide();
        return;
      }
      const response = await createData("programs", props.courseData, true);
      const newCourseId = response.id;
      const updatedCourseData = { ...props.courseData, id: newCourseId };
      props.handleSnackbar("Kurs erfolgreich erstellt", "success");
      props.updateCourse(updatedCourseData);
      props.onHide();
    } catch (error) {
      props.handleSnackbar(
        "Fehler beim Erstellen/Bearbeiten des Kurses",
        "error"
      );
    } finally {
      setLoading(false);
    }
  };

  const switchContent = (content) => {
    setModalContent(content);
  };

  return (
    <Modal {...props} size="lg" centered>
      <Form onSubmit={handleSubmit}>
        <Modal.Header closeButton>
          <Modal.Title>
            {modalContent === "course"
              ? "Schritt 1/2 - Neuen Kurs erstellen"
              : "Schritt 2/2 - Einheiten hinzufügen"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {modalContent === "course" ? (
            <CourseForm {...props} />
          ) : (
            <CourseExerciseTabs
              exercises={props.courseData.exercises}
              setExercises={(newExercises) => {
                props.setCourseData({
                  ...props.courseData,
                  exercises: newExercises,
                });
              }}
            />
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => switchContent("course")}>
            1. Kursübersicht
          </Button>
          <Button variant="primary" onClick={() => switchContent("exercise")}>
            2. Einheitenübersicht
          </Button>
          <Button type="submit" disabled={loading}>
            {loading ? <CircularProgress size={20} /> : "Speichern"}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}

function CourseForm({ courseData, setCourseData }) {
  const [useImage, setUseImage] = useState(!!courseData.image);
  const [changeFontColor, setChangeFontColor] = useState(false);
  const [newLink, setNewLink] = useState("");
  const [categories, setCategories] = useState([]);
  const [subcategories, setSubcategories] = useState([]);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const categories = await fetchData("categories");
        setCategories(categories);
      } catch (error) {
        console.error("Failed to fetch categories:", error);
      }
    };

    fetchCategories();
  }, []);

  useEffect(() => {
    const fetchSubcategories = async () => {
      if (!courseData.category_id) return;
      try {
        const category = categories.find((cat) => {
          const isMatch = cat.id === Number(courseData.category_id);
          return isMatch;
        });
        setSubcategories(category ? category.subcategories : []);
      } catch (error) {}
    };

    fetchSubcategories();
  }, [courseData.category_id, categories]);

  const sketchPickerStyles = {
    default: {
      picker: {
        width: "150px",
      },
      presetColors: {
        display: "none",
      },
    },
  };

  const handleColorChange = (color) => {
    setCourseData({ ...courseData, color: color.hex });
  };

  const handleFileChange = (e) => {
    const files = Array.from(e.target.files);
    const newAttachments = files?.map((file) => ({ name: file.name, file }));
    setCourseData({
      ...courseData,
      attachments: [...(courseData.attachments || []), ...newAttachments],
    });
  };

  const handleAddLink = () => {
    if (newLink.trim() !== "") {
      setCourseData({
        ...courseData,
        links: [...(courseData.links || []), newLink],
      });
      setNewLink("");
    }
  };

  const handleRemoveAttachment = (index) => {
    const updatedAttachments = courseData.attachments.filter(
      (_, i) => i !== index
    );
    setCourseData({ ...courseData, attachments: updatedAttachments });
  };

  const handleRemoveLink = (index) => {
    const updatedLinks = courseData.links.filter((_, i) => i !== index);
    setCourseData({ ...courseData, links: updatedLinks });
  };

  const handleBgImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      console.log("Selected file", file);
      setCourseData({ ...courseData, image: file });
    } else {
      console.log("No file selected");
    }
  };

  const handleDeleteImage = async () => {
    const confirmation = window.confirm(
      "Sind Sie sicher, dass Sie das Bild dauerhaft löschen möchten?"
    );
    if (!confirmation) return;

    try {
      await deleteImage("programs/delete_image", courseData.id);
      setCourseData({ ...courseData, image: null, image_url: null });
      setUseImage(false);
      console.log("Image deleted successfully");
    } catch (error) {
      console.error("Failed to delete image", error);
    }
  };

  const handleFontColorChange = (color) => {
    setCourseData({ ...courseData, font_color: color.hex });
    console.log(courseData.font_color);
  };

  return (
    <div>
      <FormGroup controlId="courseName">
        <FormLabel className="form-label">Name</FormLabel>
        <FormControl
          type="text"
          placeholder="Kursnamen angeben"
          value={courseData.name}
          onChange={(e) =>
            setCourseData({ ...courseData, name: e.target.value })
          }
        />
      </FormGroup>
      <FormGroup controlId="courseCategory">
        <FormLabel className="form-label">Kategorie</FormLabel>
        <FormControl
          as="select"
          value={courseData.category_id || ""}
          onChange={(e) =>
            setCourseData({ ...courseData, category_id: e.target.value })
          }
        >
          <option value="">Kategorie auswählen</option>
          {categories.map((category) => (
            <option key={category.id} value={category.id}>
              {category.name}
            </option>
          ))}
        </FormControl>
      </FormGroup>
      {courseData.category_id && (
        <FormGroup controlId="courseSubcategory">
          <FormLabel className="form-label">Unterkategorie</FormLabel>
          <FormControl
            as="select"
            value={courseData.subcategory_id || ""}
            onChange={(e) =>
              setCourseData({ ...courseData, subcategory_id: e.target.value })
            }
          >
            <option value="">Unterkategorie auswählen (optional)</option>
            {subcategories.map((subcategory) => (
              <option key={subcategory.id} value={subcategory.id}>
                {subcategory.name}
              </option>
            ))}
          </FormControl>
        </FormGroup>
      )}
      <FormGroup controlId="courseCategory">
        <FormLabel className="form-label">Bezeichnung der Einheiten</FormLabel>
        <FormControl
          type="text"
          placeholder="Einheiten"
          value={courseData.unit_name}
          onChange={(e) =>
            setCourseData({ ...courseData, unit_name: e.target.value })
          }
        />
      </FormGroup>
      <FormGroup controlId="courseDescription">
        <FormLabel className="form-label">Beschreibung</FormLabel>
        <FormControl
          as="textarea"
          rows={3}
          value={courseData.description}
          onChange={(e) =>
            setCourseData({ ...courseData, description: e.target.value })
          }
        />
      </FormGroup>
      <FormGroup controlId="courseFree">
        <FormLabel className="form-label">Kostenlos?</FormLabel>
        <FormCheck
          type="checkbox"
          label="Dieser Kurs ist kostenlos"
          checked={courseData.free || false}
          onChange={(e) =>
            setCourseData({ ...courseData, free: e.target.checked })
          }
        />
      </FormGroup>
      <FormGroup controlId="coursePrice">
        <FormLabel className="form-label">Preis</FormLabel>
        <FormControl
          type="number"
          placeholder="Preis in Euro"
          value={courseData.price}
          onChange={(e) =>
            setCourseData({ ...courseData, price: e.target.value })
          }
          disabled={courseData.free || false}
        />
      </FormGroup>
      <FormGroup controlId="courseOrderRequired">
        <FormLabel className="form-label">Reihenfolge verplichtend?</FormLabel>
        <FormCheck
          type="checkbox"
          label="Reihenfolge verpflichtend?"
          checked={
            courseData.orderRequired === "true" ||
            courseData.orderRequired === true
          }
          onChange={(e) =>
            setCourseData({
              ...courseData,
              orderRequired: e.target.checked.toString(),
            })
          }
        />
      </FormGroup>
      <FormGroup controlId="courseBgType">
        <FormLabel>Hintergrundtyp</FormLabel>
        <Switch
          checked={useImage}
          onChange={() => setUseImage((prev) => !prev)}
          name="useImage"
          color="primary"
        />
        {useImage ? "Bild" : "Farbe"}
      </FormGroup>
      {!useImage ? (
        <FormGroup controlId="courseColor">
          <FormLabel className="form-label">Hintergrundfarbe</FormLabel>
          <SketchPicker
            color={courseData.color}
            onChangeComplete={handleColorChange}
            styles={sketchPickerStyles}
          />
        </FormGroup>
      ) : (
        <FormGroup controlId="courseBgImage">
          <FormLabel className="form-label">
            HintergrundBild hochladen
          </FormLabel>
          {courseData.image_url ? (
            <div>
              <img src={courseData.image_url} alt="Preview" width="200" />
              <Button variant="danger" onClick={handleDeleteImage}>
                Bild löschen
              </Button>
            </div>
          ) : (
            <FormControl
              type="file"
              accept="image/*"
              onChange={handleBgImageChange}
            />
          )}
        </FormGroup>
      )}
      <FormGroup controlId="courseFontColorChange">
        <FormLabel>Schriftfarbe ändern?</FormLabel>
        <Switch
          checked={changeFontColor}
          onChange={() => setChangeFontColor((prev) => !prev)}
          name="changeFontColor"
          color="primary"
        />
      </FormGroup>
      {changeFontColor && (
        <FormGroup controlId="courseFontColor">
          <FormLabel className="form-label">Schriftfarbe</FormLabel>
          <SketchPicker
            color={courseData.font_color || "#000000"}
            onChangeComplete={handleFontColorChange}
            styles={sketchPickerStyles}
            className="custom-sketch-picker"
          />
        </FormGroup>
      )}
      <FormGroup controlId="courseStartDate">
        <FormLabel className="form-label">Startdatum: </FormLabel>
        <DatePicker
          selected={courseData.valid_from}
          onChange={(date) =>
            setCourseData({ ...courseData, valid_from: date })
          }
          dateFormat="yyyy-MM-dd"
          className="form-control"
          placeholderText="Startdatum wählen"
        />
      </FormGroup>
      <FormGroup controlId="courseEndDate">
        <FormLabel className="form-label">Enddatum: </FormLabel>
        <DatePicker
          selected={courseData.valid_till}
          onChange={(date) =>
            setCourseData({ ...courseData, valid_till: date })
          }
          dateFormat="yyyy-MM-dd"
          className="form-control"
          placeholderText="Enddatum wählen"
        />
      </FormGroup>
      <FormGroup controlId="attachments">
        <FormLabel className="form-label">Anhänge</FormLabel>
        <div
          style={{
            border: "2px dashed #ddd",
            padding: "20px",
            textAlign: "center",
          }}
        >
          <input
            type="file"
            accept="application/pdf"
            multiple
            onChange={handleFileChange}
            style={{ display: "none" }}
            id="file-input"
          />
          <label htmlFor="file-input" style={{ cursor: "pointer" }}>
            <div>+ PDF hochladen</div>
          </label>
        </div>
        <ul>
          {courseData.attachments?.map((attachment, index) => (
            <li
              key={index}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <span>{attachment.name}</span>
              <CloseIcon
                style={{ cursor: "pointer", marginLeft: "10px" }}
                onClick={() => handleRemoveAttachment(index)}
              />
            </li>
          ))}
        </ul>
      </FormGroup>
      <FormGroup controlId="links">
        <FormLabel className="form-label">Links</FormLabel>
        <div style={{ display: "flex", marginBottom: "10px" }}>
          <FormControl
            type="text"
            placeholder="https://google.com -> bitte https:// hinzufügen"
            value={newLink}
            onChange={(e) => setNewLink(e.target.value)}
          />
          <Button onClick={handleAddLink} style={{ marginLeft: "10px" }}>
            Hinzufügen
          </Button>
        </div>
        <ul>
          {courseData.links?.map((link, index) => (
            <li key={index} style={{ display: "flex", alignItems: "center" }}>
              <a
                href={link}
                target="_blank"
                rel="noopener noreferrer"
                style={{ marginRight: "10px" }}
              >
                {link}
              </a>
              <CloseIcon
                style={{ cursor: "pointer" }}
                onClick={() => handleRemoveLink(index)}
              />
            </li>
          ))}
        </ul>
      </FormGroup>
    </div>
  );
}

CourseModal.defaultProps = {
  courseData: {
    name: "",
    category: "",
    description: "",
    color: "#ffffff",
    orderRequired: false,
  },
  setCourseData: () => {},
};
