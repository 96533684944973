import React, { createContext, useState, useEffect } from "react";
import useToken from "./useToken";
import isAdmin from "./isAdmin";

export const AuthContext = createContext();

// Inside AuthProvider
export const AuthProvider = ({ children }) => {
  const { token, setToken } = useToken();
  const [isAuthenticated, setIsAuthenticated] = useState(!!token);
  const [adminUser, setAdminUser] = useState(false);

  useEffect(() => {
    setIsAuthenticated(!!token);
    if (token) {
      // Check admin status only if authenticated
      const checkAdminStatus = async () => {
        const status = await isAdmin();
        setAdminUser(status.isAdmin);
      };
      checkAdminStatus();
    } else {
      setAdminUser(false);
    }
  }, [token]);

  return (
    <AuthContext.Provider value={{ isAuthenticated, setToken, adminUser }}>
      {children}
    </AuthContext.Provider>
  );
};
