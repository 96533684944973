import React, { useState, useEffect } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs"; // This could be from a library like 'react-tabs'
import "react-tabs/style/react-tabs.css"; // Or your custom styling
import {
  Button,
  TextField,
  FormControl,
  FormLabel,
  IconButton,
} from "@mui/material";
import { Checkbox } from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import CloseIcon from "@mui/icons-material/Close";

function CourseExerciseTabs({ exercises, setExercises }) {
  const [tabIndex, setTabIndex] = useState(0);

  useEffect(() => {
    setTabIndex(exercises.length - 1);
  }, [exercises.length]);

  const addNewExercise = () => {
    const newExercise = {
      name: "",
      description: "",
      audio: null,
      audioPreview: null,
    };
    setExercises([...exercises, newExercise]);
  };

  const updateExercise = (index, field, value) => {
    const updatedExercise = {
      ...exercises[index],
      [field]: value,
    };
    setExercises([
      ...exercises.slice(0, index),
      updatedExercise,
      ...exercises.slice(index + 1),
    ]);
  };

  const removeExercise = (index) => {
    setExercises([...exercises.slice(0, index), ...exercises.slice(index + 1)]);
    setTabIndex((prevTabIndex) => {
      if (exercises.length === 1) return 0;
      return index === exercises.length - 1 ? prevTabIndex - 1 : prevTabIndex;
    });
  };

  const handleAudioUpload = (index, file) => {
    const currentExercise = exercises[index];
    if (currentExercise.audioPreview) {
      URL.revokeObjectURL(currentExercise.audioPreview);
    }
    const url = URL.createObjectURL(file);
    setExercises([
      ...exercises.slice(0, index),
      {
        ...currentExercise,
        audio: file,
        audioPreview: url,
      },
      ...exercises.slice(index + 1),
    ]);
  };

  return (
    <div>
      <Tabs selectedIndex={tabIndex} onSelect={(index) => setTabIndex(index)}>
        <TabList>
          {Array.isArray(exercises) && exercises.length > 0 ? (
            exercises.map((exercise, index) => (
              <Tab key={index}>
                Einheit {index + 1}
                <IconButton onClick={() => removeExercise(index)}>
                  <CloseIcon />
                </IconButton>
              </Tab>
            ))
          ) : (
            <Tab disabled>Keine Übungen verfügbar</Tab> // Display a disabled tab or other appropriate fallback
          )}
          <IconButton onClick={addNewExercise}>
            <AddCircleOutlineIcon />
          </IconButton>
        </TabList>

        {Array.isArray(exercises) && exercises.length > 0 ? (
          exercises.map((exercise, index) => (
            <TabPanel key={index}>
              <FormControl fullWidth margin="normal">
                <FormLabel className="kiro-font">Name</FormLabel>
                <TextField
                  value={exercise.name}
                  onChange={(e) =>
                    updateExercise(index, "name", e.target.value)
                  }
                  className="kiro-font"
                />
              </FormControl>
              <FormControl fullWidth margin="normal">
                <FormLabel className="kiro-font">Beschreibung</FormLabel>
                <TextField
                  multiline
                  rows={3}
                  value={exercise.description}
                  onChange={(e) =>
                    updateExercise(index, "description", e.target.value)
                  }
                  className="kiro-font"
                />
              </FormControl>
              <Button
                variant="contained"
                component="label"
                className="kiro-font"
              >
                Audiodatei
                <input
                  type="file"
                  hidden
                  accept="audio/*"
                  onChange={(e) => handleAudioUpload(index, e.target.files[0])} // Moved onChange to correct location
                />
              </Button>
              {exercise.audioPreview && (
                <audio controls src={exercise.audioPreview}>
                  Your browser does not support the audio element.
                </audio>
              )}
            </TabPanel>
          ))
        ) : (
          <TabPanel>
            <div className="text-center">Keine Übungen verfügbar</div>
          </TabPanel>
        )}
      </Tabs>
    </div>
  );
}

export default CourseExerciseTabs;
