import React, { useState, useEffect } from "react";
import {
  Modal,
  Button,
  Form,
  ListGroup,
  ListGroupItem,
  Checkbox,
} from "react-bootstrap";

function EditGroupModal({ show, handleClose, group, onSave, allUsers }) {
  const [groupName, setGroupName] = useState(group.name);
  const [selectedUsers, setSelectedUsers] = useState(
    group.group_accounts.map((account) => account.account_id)
  );

  useEffect(() => {
    if (show) {
      setGroupName(group.name);
      setSelectedUsers(
        group.group_accounts.map((account) => account.account_id)
      );
    }
  }, [group, show]);

  const handleToggle = (event, userId) => {
    event.preventDefault();
    const currentIndex = selectedUsers.indexOf(userId);
    const newChecked = [...selectedUsers];

    if (currentIndex === -1) {
      newChecked.push(userId);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setSelectedUsers(newChecked);
  };

  const handleSave = () => {
    onSave(group.id, groupName, selectedUsers);
    handleClose();
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Edit Group</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group className="mb-3" controlId="group-name">
            <Form.Label>Group Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter group name"
              value={groupName}
              onChange={(e) => setGroupName(e.target.value)}
            />
          </Form.Group>
          <ListGroup>
            {allUsers.map((user) => (
              <ListGroupItem
                key={user.id}
                action
                onClick={(e) => handleToggle(e, user.id)}
              >
                <Form.Check
                  type="checkbox"
                  checked={selectedUsers.includes(user.id)}
                  label={user.email}
                  onChange={() => {}} // Stop the event from bubbling up
                />
              </ListGroupItem>
            ))}
          </ListGroup>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button variant="primary" onClick={handleSave}>
          Save Changes
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default EditGroupModal;
