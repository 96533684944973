import React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";

function GroupList({ groups = [], fetchGroups, deleteGroup, editGroup }) {
  const handleDelete = (groupId) => {
    deleteGroup(groupId).then(() => {
      fetchGroups(); // Refresh the group list after deletion
    });
  };

  const handleEdit = (groupId) => {
    editGroup(groupId); // This can trigger a modal/dialog with form to edit the group
  };

  return (
    <div className="group-list">
      <div className="list-heading">Gruppen</div>
      {groups.map((group) => (
        <Accordion key={group.id}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>{group.name}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography component="div">
              <ul>
                {group.group_accounts.map((account) => (
                  <li key={account.account_id}>
                    {account.account_id} - {account.account.email}
                  </li>
                ))}
              </ul>
              <IconButton onClick={() => handleEdit(group)} color="primary">
                <EditIcon />
              </IconButton>
              <IconButton onClick={() => handleDelete(group.id)} color="error">
                <DeleteIcon />
              </IconButton>
            </Typography>
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  );
}

export default GroupList;
