import React, { useState, useRef, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import axios from "axios";
import Carousel from "react-bootstrap/Carousel";
import ReactPlayer from "react-player";
import "../../assets/stylesheets/Utils.css";
import { useTranslation } from "react-i18next";
import { Collapse } from "react-bootstrap";
import { resetPassword } from "../../api/api";

async function sendResetPasswordRequest(mail, props) {
  try {
    if (!mail) {
      return;
    }
    await resetPassword("/account/reset_password", { email: mail });
    props.setPasswordSuccess();
  } catch (error) {
    props.setPasswordFailed();
  }
}

export default function ResetPassword(props) {
  const [mail, setMail] = useState("");
  const { t, i18n } = useTranslation();

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    sendResetPasswordRequest(mail, props);
    props.onHide();
    event.preventDefault();
    event.stopPropagation();
  };

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Form onSubmit={handleSubmit}>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {t("password.forgot")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>{t("password.mail")}</Form.Label>
            <Form.Control
              type="email"
              placeholder="user@ernaapp.de"
              value={mail}
              pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
              required
              autoFocus
              onChange={(e) => setMail(e.target.value)}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button className="bs-button-color" type="submit">
            {t("actions.send")}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}
